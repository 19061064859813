import {HTTP} from '@/libs/https.js';
import config from '@/libs/config.js';

class TemperatureMonitoringModel extends HTTP {
    getTemperatureList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_TEMPERATURE_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    getSchoolAddressList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.EQUIPMENT_SCHOOL_ADDRESS_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    getSchoolOrganList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_SCHOOL_ORGAN_ALL,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
}

export {TemperatureMonitoringModel};
