import { HTTP } from '@/libs/https.js';
import config from '@/libs/config.js';

class CardCheckingModel extends HTTP {
  // 获取账单列表
  getBillList (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.GET_BILL_LIST,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    })
  }

  // 获取对账报表数据统计合计
  getBillStatistics (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.GET_BILL_STATISTICS,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    })
  }

  // 获取对账报表数据统计详情
  getBillStatisticsDetail (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.GET_BILL_STATISTICS_DETAIL,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    })
  }

  // 对账设置
  getCheckingConfig (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.GET_CHECKING_CONFIG,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    })
  }
  // 更新对账设置
  updateCheckingConfig (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.SAVE_CHECKING_CONFIG,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    })
  }
}

export { CardCheckingModel };
