import { HTTP } from '@/libs/https.js';
import config from '@/libs/config.js';

class VideoSurveillanceModel extends HTTP {
    getEquipmentList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_EQUIPMENT_LIST,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    };

    getGroupList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_GROUP_LIST,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    };

    synchronizationDevice(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.SYNCHRONIZATION_DEVICE,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    };

    getClassList (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_CLASS_LIST,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    };

    schoolVideoMonitoringEquipmentSave (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.SCHOOL_VIDEO_MONITORING_EQUIPMENT_SAVE,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    };

    editDevice (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.EDIT_DEVICE,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    };

    setBatchGroup (data) {
        return new Promise((resolve, reject) => {
            this.axiosPostArray({
                url: config.API.SET_BATCH_GROUP,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    };

    setBatchDebt (data) {
        return new Promise((resolve, reject) => {
            this.axiosPostArray({
                url: config.API.SET_BATCH_DEBT,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    };

    getEchoData (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_ECHO_DATA,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    };
}

export { VideoSurveillanceModel };
