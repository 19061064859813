import { HTTP } from '@/libs/https.js'
import config from '@/libs/config.js';
class ThemeColorModel extends HTTP {
 
    // 修改主题
    changeThemeColor (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.EDIT_USER_TEMPLATE_INFO,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }
}

export { ThemeColorModel }
