import { HTTP } from '@/libs/https.js'
import config from '@/libs/config.js';
class TestModel extends HTTP {
  // 成绩分析导入
  examUpload (data) {
    return new Promise((resolve, reject) => {
      this.axiosFile({
        url: '/api/school/schoolExam/upload',
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }
  // 成绩分析导入新，可导入排名
  examNewUpload(data) {
    return new Promise((resolve, reject) => {
      this.axiosFile({
        url: '/api/school/schoolExam/newUpload',
        data: data,
        header: 'application/json',
        success(data) {
          resolve({
            data,
            status: 0
          });
        },
        error(error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }
  // 教师档案导入
  teacherImportInfo (data) {
    return new Promise((resolve, reject) => {
      this.axiosFile({
        url: '/api/school/schoolTeacher/importInfo',
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }
//   教师档案删除
  teacherDeleteInfo(data){
    return new Promise((resolve, reject) => {
      this.axiosPostArray({
        url: config.API.REMOVE_SCHOOL_TEACHER,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }

  // 学生档案导入
  studentImportInfo (data) {
    return new Promise((resolve, reject) => {
      this.axiosFile({
        url: '/api/school/schoolStudent/upload',
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }
}

export { TestModel }
