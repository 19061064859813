import { HTTP } from '@/libs/https.js';
import config from '@/libs/config.js';

class TeachingResearchCourseScheduleTempModel extends HTTP {
    getSchoolSubjectsList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_SCHOOL_SUBJECTS_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getSchoolClassList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_SCHOOL_CLASS_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getSchoolTeacherList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_SCHOOL_TEACHER_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getSchoolCourseSave(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.SCHOOL_COURSE_SAVE,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getClassWeek(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_CLASS_WEEK,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getGradeList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_GRADE_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getCalibrateCalendarTime (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_CALIBRATE_CALENDAR_TIME,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getCourseDuplication (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_COURSE_DUPLICATION,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getSchoolCourseList (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_SCHOOL_COURSE_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    getSchoolCourseListByAuth (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_SCHOOL_COURSE_LIST_BYAUTH,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    deleteSchoolCourse (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.DELETE_SCHOOL_COURSE,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    teacherStudentBehaviorLabelsTake (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.TEACHER_STUDENT_BEHAVIOR_LABELS_TAKE_VALUES,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    schoolClassRecordSave (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.SCHOOL_CLASS_RECORD_SAVE,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getSchoolClassRecordInfo (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_SCHOOL_CLASS_RECORD_INFO,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getContinueTourInfo (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_CONTINUE_TOUR_INFO,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getTeaOrganByTeacherId (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_TEA_ORGAN_BY_TEACHER_ID,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getClassByGradeId (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_CLASS_BY_GRADE_ID,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getCourseInfo (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_COURSE_INFO,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getDepartmentList (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_DEPARTMENT_LIST_ORGAN,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getGradeOfTree (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.COURSE_SCHEDULE_GET_GRADE_TREE_SHAPE,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getGradeOfTreeByUser (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.COURSE_SCHEDULE_GET_GRADE_TREE_BYUSER,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getSpliceOrganList (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.COURSE_SCHEDULE_YEAR_GET_CLASS,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    emptyCourseTableData(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.COURSE_EMPTY,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    batchDelCourseData(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.BATCH_DELETE_COURSE,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    getGradeClassListData(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.Grade_CLASS_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    // 课程档案导入
    courseImportInfo (data) {
    return new Promise((resolve, reject) => {
      this.axiosFile({
        url: config.API.COURSE_IMPORT_INFO,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }
  getClassNameTeacherData(data) {
    return new Promise((resolve, reject) => {
        this.axiosPost({
            url: config.API.CLASSNAME_MASTERTEACHER,
            data: data,
            header: 'application/json',
            success(data) {
                resolve({
                    data,
                    status: 0
                });
            },
            error(error) {
                resolve({
                    error,
                    status: -1
                })
            }
        });
      });
    }

  getSchoolCourseCountData(data) {
    return new Promise((resolve, reject) => {
        this.axiosPost({
            url: config.API.GET_SCHOOL_COURSE_COUNT,
            data: data,
            header: 'application/json',
            success(data) {
                resolve({
                    data,
                    status: 0
                });
            },
            error(error) {
                resolve({
                    error,
                    status: -1
                })
            }
        });
      });
    }

    /**
     * @Description: 清空课表前校验是否已设置巡课方案
     * @Author: mrz
     * @Date: 2024-08-28 11:22:50
     */
    getEmptyBeforeCheck(data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_EMPTY_BEFORE_CHECK,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
}

export { TeachingResearchCourseScheduleTempModel };
